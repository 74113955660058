<script module lang="ts">
	type TProps = {
		colour?: TColours | undefined;
		fullWidth?: boolean;
		fullHeight?: boolean;
		transition?: boolean;
		children?: Snippet;
	};
</script>

<script lang="ts">
	import type { TColours } from '$lib/data/colours';
	import classNames from 'classnames';
	import type { Snippet } from 'svelte';
	const {
		colour,
		fullWidth = false,
		fullHeight = false,
		transition = false,
		children,
	}: TProps = $props();

	const classes = $derived(
		classNames('Plane', `Plane--colour-${colour}`, {
			'Plane--fullWidth': fullWidth,
			'Plane--fullHeight': fullHeight,
			'Plane--transition': transition,
		}),
	);
</script>

<div class={classes}>
	{#if children}
		{@render children()}
	{/if}
</div>

<style lang="scss">
	@import '$lib/styles/colours.scss';

	.Plane {
		position: relative;
		display: inline-flex;
		// min-width: var(--padding);
		// min-height: var(--padding);

		&--transition {
			transition: all 0.3s var(--easing);
		}

		&--fullWidth {
			width: 100%;
		}

		&--fullHeight {
			height: 100%;
		}

		@each $colour in $colours {
			&--colour-#{$colour} {
				background-color: var(--#{$colour});
			}
		}
	}
</style>
